import { cache, createAsync, useParams } from "@solidjs/router";
import { BrandCard } from "~/components/brand/brand_card";
import {
  Header,
  isFreshLogin,
  setIsFreshLogin,
} from "~/components/brand_l2/header";
import { Footer } from "~/components/footer";
import {
  Accessor,
  createEffect,
  createMemo,
  createSignal,
  For,
  onMount,
  Show,
  Suspense,
} from "solid-js";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import { CategoryStrip } from "~/components/home/category_card";
import {
  getGroupsRouteData,
  GroupsRouteData,
} from "~/server/data/groups_route_data";
import {
  rehypeUppercaseATags,
  getCategoryNameFromSlug,
  getHighestPercentOff,
  getCategoryTitleFromSlug,
} from "~/utils/common";
import attachPopStateListener from "~/utils/popstate_listener";
import { DottedLoader } from "~/widgets/loader";
import {
  GroupTextHeaderOccasion,
  TrustMakerStrip,
} from "~/components/groups/content";
import SolidMarkdown from "solid-markdown";
import rehypeRaw from "rehype-raw";
import occasionsData from "~/data/groups/occasions.json";
import recipientsData from "~/data/groups/recipients.json";
import { BrandingRow, HubbleBranding } from "~/widgets/branding";
import { Link, Meta, Title } from "@solidjs/meta";
import { zIndexes } from "~/z_indexes";
import { getCoinsSummary } from "~/server/apis/client_apis";

const getGroupRouteData$C = cache(getGroupsRouteData, "occasion");

export default function OccasionGroupPage() {
  const params = useParams();

  onMount(() => {
    attachPopStateListener();
  });

  const routeData: Accessor<GroupsRouteData | undefined> =
    createAsync<GroupsRouteData>(
      () => {
        return getGroupRouteData$C(
          "occasion",
          getCategoryNameFromSlug(params.occasion)
        );
      },
      {
        deferStream: true,
      }
    );

  const [showCategoryList, setShowCategoryList] = createSignal(false);
  const [latestCoinsBalance, setLatestCoinsBalance] = createSignal<
    number | undefined
  >(undefined);
  const [refreshLatestBalance, setRefreshLatestBalance] = createSignal(false);

  const isLoggedIn = createMemo<boolean>(() => {
    return isFreshLogin() ?? false;
  });

  createEffect(() => {
    setIsFreshLogin(routeData()?.isLoggedIn ?? false);
  });

  async function fetchLatestBalance() {
    const response = await getCoinsSummary();
    setLatestCoinsBalance(response.totalAvailable);
  }

  createEffect(async () => {
    if (refreshLatestBalance()) {
      await fetchLatestBalance();
      setRefreshLatestBalance(false);
    }
  });

  createEffect(async () => {
    if (isLoggedIn()) {
      await fetchLatestBalance();
    }
  });

  const getCoins = createMemo<number>(() => {
    return (
      latestCoinsBalance() ?? routeData()?.coinsSummary?.totalAvailable ?? 0
    );
  });

  return (
    <Suspense fallback={<DottedLoader color="#999" />}>
      <div class="relative min-h-screen">
        <ClientOnlyComponent component={ClientComponent.ModalHost} />
        <ClientOnlyComponent component={ClientComponent.SnackbarHost} />

        <Show when={routeData() && routeData()?.groupDetails}>
          <Meta name="robots" content="follow, index" />
          <Title>{routeData()?.groupDetails.metadata.title}</Title>
          <Link
            rel="canonical"
            href={`https://www.myhubble.money/shop-by-occassion/${params.occasion}`}
          />
          <Meta name="og:type" content="product" />
          <Meta
            name="og:title"
            content={routeData()?.groupDetails.metadata.title}
          />
          <Meta
            name="description"
            content={`${routeData()?.groupDetails.metadata.description}`}
          />
          <Meta
            name="og:description"
            content={`${routeData()?.groupDetails.metadata.description}`}
          />
        </Show>

        <Header
          showActions={true}
          isLoggedIn={isLoggedIn}
          headerImageUrl={routeData()?.clientConfig?.clientLogoUrl}
          mode={routeData()?.mode ?? "hubble"}
          coins={getCoins}
          showCoins={() =>
            routeData()?.mode === "hubble" || routeData()?.mode === "rnr"
          }
          setRefreshCoins={setRefreshLatestBalance}
        />
        <Show
          when={routeData()?.categoryList && routeData()?.categoryList != null}
        >
          <div
            class=" sticky top-10 w-full pb-3 pt-1 sm:top-[4.5rem]"
            style={{
              "z-index": zIndexes.categoryStrip,
            }}
          >
            <div class="hidden sm:flex">
              <CategoryStrip
                categories={routeData()!.categoryList.data}
                scroll={false}
                border={true}
                maxCategoriesOnStrip={Math.min(
                  10,
                  routeData()!.categoryList.data.length
                )}
                occasions={occasionsData.occasions}
                recipients={recipientsData.recipients}
                showCategoryList={showCategoryList}
                setShowCategoryList={setShowCategoryList}
              ></CategoryStrip>
            </div>
            <div class="flex sm:hidden">
              <CategoryStrip
                categories={routeData()!.categoryList.data}
                scroll={true}
                border={false}
                occasions={occasionsData.occasions}
                recipients={recipientsData.recipients}
                showCategoryList={showCategoryList}
                setShowCategoryList={setShowCategoryList}
              ></CategoryStrip>
            </div>
          </div>
        </Show>

        <div class=" ml-auto mr-auto lg:w-[1136px]">
          <Show when={routeData()?.mode === "hubble"}>
            <GroupTextHeaderOccasion
              text={`Enjoy up to ${getHighestPercentOff(routeData()?.brandList.data[0].products!)}% off on ${routeData()?.groupDetails.header}`}
            />

            <div class="mx-4 border-y-[1px] border-y-gray-200">
              <TrustMakerStrip />
            </div>
          </Show>

          <div class="flex items-center px-4 pt-4 lg:px-0">
            <Show when={routeData()?.mode !== "hubble"}>
              <div class="hidden text-h4 text-basePrimaryDark lg:flex">
                {getCategoryTitleFromSlug(params.occasion)}
              </div>
              <div class="mx-[6px] hidden h-1 w-1 rounded-full bg-baseSecondaryLight lg:flex"></div>
              <div class="text-bold lg:text-textNormal">
                {routeData()?.brandList.data[0].products.length} brands
              </div>
              <div class="ml-4 hidden h-[1px] grow bg-baseTertiaryDark lg:flex"></div>
            </Show>
          </div>

          <div class="mb-16 w-full px-4 lg:px-0">
            <div class="noScrollbar mt-5 grid grid-cols-3 gap-6 gap-x-3 gap-y-6 overflow-auto  lg:grid-cols-6">
              <For each={routeData()?.brandList.data[0].products}>
                {(brand) => (
                  <BrandCard
                    imageUrl={brand.voucherProduct.iconImageUrl}
                    name={brand.voucherProduct.title}
                    categoryName={brand.displayTags[0] || ""}
                    discountPercentage={brand.voucherProduct.discountPercentage}
                    brandKey={brand.brandKey}
                    showHubbleCoinReward={
                      brand.voucherProduct.rewardType === "POSTPAID"
                    }
                  />
                )}
              </For>
            </div>
          </div>

          <Show
            when={
              routeData()?.groupDetails.footNote &&
              routeData()?.mode === "hubble"
            }
          >
            <SolidMarkdown
              class="markdown"
              rehypePlugins={[rehypeUppercaseATags, rehypeRaw] as any}
            >
              {routeData()?.groupDetails.footNote}
            </SolidMarkdown>
          </Show>
        </div>

        <Show when={routeData()?.mode === "hubble"}>
          <footer class="flex justify-center lg:mx-auto lg:max-w-[1136px]">
            <Footer />
          </footer>
        </Show>

        <Show when={routeData()?.mode === "rnr"}>
          <HubbleBranding class="hidden w-full py-4 sm:flex" />
          <BrandingRow class="fixed bottom-0 left-0 right-0 items-center sm:hidden" />
        </Show>
      </div>
    </Suspense>
  );
}
